import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
  writeBatch,
  Unsubscribe,
} from "firebase/firestore";

export const useIEWProductCategories = definePiniaStore(
  "/iewProductCategories",
  () => {
    const allIEWProductCategories = ref<IEWProductCategory[]>([]);

    const isInitialized = ref(false);
    const subscription = ref<Unsubscribe | undefined>();

    const initializeIEWProductCategories = async () => {
      if (isInitialized.value) return;

      const db = useFirestore();
      const iewProductCategoriesCollectionRef = collection(
        db,
        "iewProductCategories"
      );
      const iewProductCategoriesCollectionQuery = query(
        iewProductCategoriesCollectionRef,
        orderBy("displayOrder", "asc")
      );

      var iewProductCategoriesSnapshot = await getDocs(
        iewProductCategoriesCollectionQuery
      );
      allIEWProductCategories.value = iewProductCategoriesSnapshot.docs.map(
        (doc) => {
          return {
            ...doc.data(),
            id: doc.id,
          } as IEWProductCategory;
        }
      );

      if (subscription.value) {
        subscription.value();
      }

      subscription.value = onSnapshot(
        iewProductCategoriesCollectionQuery,
        (snapshot) => {
          allIEWProductCategories.value = snapshot.docs.map((doc) => {
            return {
              ...doc.data(),
              id: doc.id,
            } as IEWProductCategory;
          });
        }
      );

      isInitialized.value = true;
    };

    const allUserIEWProductCategories = computed(() => {
      const appUserData = useAppUserData();
      const { availableIEWProductCategoryIds } = storeToRefs(appUserData);

      if (
        availableIEWProductCategoryIds.value == undefined ||
        availableIEWProductCategoryIds.value.length == 0
      )
        return allIEWProductCategories.value;

      return allIEWProductCategories.value.filter((level) =>
        availableIEWProductCategoryIds.value.includes(level.id)
      );
    });

    const publicIEWProductCategories = computed(() => {
      return allIEWProductCategories.value.filter(
        (category) => category.isPublic
      );
    });

    return {
      allIEWProductCategories,
      publicIEWProductCategories,
      allUserIEWProductCategories,
      initializeIEWProductCategories,
    };
  }
);
